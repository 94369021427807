<template>
  <div
    id="hide-to-print"
    class="
      flex flex-col flex-auto flex-shrink-0
      antialiased
      bg-gray-50
      text-gray-800
      border-b
    "
  >
    <div
      id="hide-to-print"
      class="fixed flex flex-col top-0 left-0 w-full bg-white border-r"
    >
      <div
        id="hide-to-print"
        class="overflow-y-auto overflow-x-hidden flex-grow"
      >
        <ul class="flex mt-10 py-4 space-y-1">
          <li>
            <router-link
              :class="{
                'bg-gray-50 border-indigo-500': $route.fullPath == '/dashboard',
              }"
              to="/dashboard"
              class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-600
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              "
            >
              <span class="inline-flex justify-center items-center ml-4">
                <svg
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  ></path>
                </svg>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Dashboard</span>
            </router-link>
          </li>

          <li>
            <router-link
              :class="{
                'bg-gray-50 border-indigo-500':
                  $route.fullPath == '/agent_transactions',
              }"
              to="/agent_transactions"
              class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              "
            >
              <span class="inline-flex justify-center items-center ml-4">
                <i class="bx bx-transfer text-xl"></i>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate"
                >Transactions</span
              >
            </router-link>
          </li>

          <li class="px-5">
            <div class="flex flex-row items-center h-8">
              <div class="text-sm font-light tracking-wide text-gray-500"></div>
            </div>
          </li>

          <li>
            <router-link
              to="/settings/profile"
              class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              "
            >
              <span class="inline-flex justify-center items-center ml-4">
                <svg
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                </svg>
              </span>
              <span class="ml-2 text-sm tracking-wide truncate">Settings</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: mapGetters({
    user: "auth/user",
  }),
};
</script>


<style scoped>
.new-menu {
  margin-left: 5px;
  margin-top: -7px;
}
@media print {
  body * {
    visibility: hidden;
  }

  #hide-to-print #hide-to-print * {
    visibility: hidden;
  }

  .__hide__to_print {
    visibility: hidden;
  }
}
</style>