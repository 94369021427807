function view(path) {
  return () =>
    import(/* webpackChunkName: '' */ `../views/${path}`).then(
      (m) => m.default || m
    );
}

export default [
  { path: "/", name: "login", component: view("auth/Login.vue") },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: view("Dashboard/Index.vue"),
  },

  {
    path: "/agent_transactions",
    name: "AgentTransactions",
    component: view("AgentTransactions/Index.vue"),
  },

  {
    path: "/settings/profile",
    name: "profile",
    component: view("Settings/Profile.vue"),
  },

  {
    path: "/settings/change_password",
    name: "settings/change_passwword",
    component: view("Settings/Password.vue"),
  },

  {
    path: "/settings/report",
    name: "settings/report",
    component: view("Settings/Report.vue"),
  },
  {
    path: "/report/result",
    name: "report_result",
    component: view("Reports/result.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: view("Service/Index.vue"),
  },

  {
    path: "/tellerportal",
    name: "tellerportal",
    component: view("TellerPortal/Index.vue"),
  },

  {
    path: "/agents",
    name: "agents",
    component: view("Agents/Index.vue"),
  },

  {
    path: "/agents/add",
    name: "agents_add",
    component: view("Agents/Add.vue"),
  },
  {
    path: "/agents/edit/:id",
    name: "editAgent",
    component: view("Agents/Edit.vue"),
  },

  {
    path: "/agent/reset_password/:id",
    // name: "reset_password",
    component: view("Agents/ResetPassword.vue"),
  },

  { path: "*", component: view("errors/404.vue") },
];
