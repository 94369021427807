export default {
  data: () => ({}),
  methods: {
    checkPermissionExist: (user, permission) => {
      if (!user) return false;

      // const role = user.roles[0];

      return (
        user.permissions.findIndex((item) => item.name === permission) !== -1
      );
    },
  },
};
