var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"bg-white fixed w-full z-10 top-0 shadow",attrs:{"id":"header"}},[_c('div',{staticClass:"w-full container mx-auto flex flex-wrap items-center mt-0 pt-3 pb-3 md:pb-0"},[_vm._m(0),_c('div',{staticClass:"w-1/2 pr-0"},[_c('div',{staticClass:"flex relative inline-block float-right"},[_c('div',{staticClass:"relative flex justify-between text-sm"},[_c('button',{staticClass:"flex items-center focus:outline-none mr-3",attrs:{"id":"userButton"}},[_c('span',{staticClass:"hidden md:inline-block"},[_vm._v("Hi, "+_vm._s(_vm.user.data.name))])]),_c('button',{staticClass:"bg-blue-600 text-gray-100 text-center rounded px-3 py-1",on:{"click":_vm.handleLogout}},[_vm._v(" LogOut ")])]),_c('div',{staticClass:"block lg:hidden pr-4"},[_c('button',{staticClass:"flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-teal-500 appearance-none focus:outline-none",attrs:{"id":"nav-toggle"}},[_c('svg',{staticClass:"fill-current h-3 w-3",attrs:{"viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('title',[_vm._v("Menu")]),_c('path',{attrs:{"d":"M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"}})])])])])]),_c('div',{staticClass:"w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white z-20",attrs:{"id":"nav-content"}},[_c('ul',{staticClass:"list-reset lg:flex flex-1 items-center px-4 md:px-0"},[_c('li',{staticClass:"mr-6 my-2 md:my-0"},[_c('router-link',{staticClass:"block py-1 md:py-3 pl-1 align-middle no-underline hover:text-red-900 border-b-2 border-orange-600 hover:border-red-600",class:{
              'text-red-600 border-b-2 border-red-600': _vm.$route.fullPath == '/dashboard',
            },attrs:{"to":"/dashboard"}},[_c('span',{staticClass:"pb-1 md:pb-0 text-sm"},[_vm._v("Dashboard")])])],1),_c('li',{staticClass:"mr-6 my-2 md:my-0"},[_c('router-link',{staticClass:"block py-1 md:py-3 pl-1 align-middle no-underline hover:text-red-900 border-b-2 border-orange-600 hover:border-red-600",class:{
              'text-red-600 border-b-2 border-red-600':
                _vm.$route.fullPath == '/tellerportal',
            },attrs:{"to":"/tellerportal"}},[_c('span',{staticClass:"pb-1 md:pb-0 text-sm"},[_vm._v("Agency Portal")])])],1),_c('li',{staticClass:"mr-6 my-2 md:my-0"},[_c('router-link',{staticClass:"block py-1 md:py-3 pl-1 align-middle no-underline hover:text-red-900 border-b-2 border-orange-600 hover:border-red-600",class:{
              'text-red-600 border-b-2 border-red-600':
                _vm.$route.fullPath == '/agent_transactions',
            },attrs:{"to":"/agent_transactions"}},[_c('span',{staticClass:"pb-1 md:pb-0 text-sm"},[_vm._v("Transactions")])])],1),(_vm.user.data.type == 'admin')?_c('li',{staticClass:"mr-6 my-2 md:my-0"},[_c('router-link',{staticClass:"block py-1 md:py-3 pl-1 align-middle no-underline hover:text-red-900 border-b-2 border-orange-600 hover:border-red-600",class:{
              'text-red-600 border-b-2 border-red-600': _vm.$route.fullPath == '/agents',
            },attrs:{"to":"/agents"}},[_c('span',{staticClass:"pb-1 md:pb-0 text-sm"},[_vm._v("Users")])])],1):_vm._e(),_c('li',{staticClass:"mr-6 my-2 md:my-0"},[_c('span',{staticClass:"pb-1 flex md:pb-0 text-sm cursor-pointer",on:{"click":_vm.handleDropDown}},[_c('span',{staticClass:"cursor-none"},[_vm._v(" Settings")]),_c('svg',{staticClass:"ml-2 w-4 h-4",attrs:{"aria-hidden":"true","fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])]),(_vm.dropdown)?_c('div',{staticClass:"z-10 absolute mt-2 w-60 bg-white shadow rounded divide-y divide-gray-100 border"},[_vm._m(1),_c('ul',{staticClass:"py-1 text-sm text-gray-700 dark:text-gray-200",attrs:{"aria-labelledby":"dropdownInformationButton"}},[_c('li',[_c('router-link',{staticClass:"block px-2 align-middle no-underline hover:text-red-900",class:{
                    'text-red-600': _vm.$route.fullPath == '/settings/change_password',
                  },attrs:{"to":"/settings/change_password"}},[_c('a',{staticClass:"block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white",attrs:{"href":"#"}},[_vm._v("Change Password")])])],1),_c('li',[_c('router-link',{staticClass:"block px-2 align-middle no-underline hover:text-red-900",class:{
                    'text-red-600': _vm.$route.fullPath == '/settings/report',
                  },attrs:{"to":{
                    path: 'settings/report',
                    params: { user_id: _vm.user.data.id },
                  }}},[_c('a',{staticClass:"block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white",attrs:{"href":"#"}},[_vm._v("Reports")])])],1)])]):_vm._e()])]),_c('div',{staticClass:"relative pull-right pl-4 pr-4 md:pr-0"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-1/2 pl-2 md:pl-0"},[_c('a',{staticClass:"text-gray-900 text-base xl:text-xl no-underline hover:no-underline font-bold",attrs:{"href":"#"}},[_c('img',{staticClass:"h-12 rounded-full",attrs:{"src":require("../assets/mainlogo.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 text-sm text-gray-900 dark:text-white"},[_c('div',[_vm._v("Your Account")])])
}]

export { render, staticRenderFns }