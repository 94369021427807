<template>
  <nav id="header" class="bg-white fixed w-full z-10 top-0 shadow">
    <div
      class="w-full container mx-auto flex flex-wrap items-center mt-0 pt-3 pb-3 md:pb-0"
    >
      <div class="w-1/2 pl-2 md:pl-0">
        <a
          class="text-gray-900 text-base xl:text-xl no-underline hover:no-underline font-bold"
          href="#"
        >
          <img class="h-12 rounded-full" src="../assets/mainlogo.png" />
        </a>
      </div>
      <div class="w-1/2 pr-0">
        <div class="flex relative inline-block float-right">
          <div class="relative flex justify-between text-sm">
            <button id="userButton" class="flex items-center focus:outline-none mr-3">
              <span class="hidden md:inline-block">Hi, {{ user.data.name }}</span>
            </button>
            <button
              @click="handleLogout"
              class="bg-blue-600 text-gray-100 text-center rounded px-3 py-1"
            >
              LogOut
            </button>
          </div>

          <div class="block lg:hidden pr-4">
            <button
              id="nav-toggle"
              class="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-teal-500 appearance-none focus:outline-none"
            >
              <svg
                class="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div
        class="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white z-20"
        id="nav-content"
      >
        <ul class="list-reset lg:flex flex-1 items-center px-4 md:px-0">
          <li class="mr-6 my-2 md:my-0">
            <router-link
              :class="{
                'text-red-600 border-b-2 border-red-600': $route.fullPath == '/dashboard',
              }"
              to="/dashboard"
              class="block py-1 md:py-3 pl-1 align-middle no-underline hover:text-red-900 border-b-2 border-orange-600 hover:border-red-600"
            >
              <span class="pb-1 md:pb-0 text-sm">Dashboard</span>
            </router-link>
          </li>
          <li class="mr-6 my-2 md:my-0">
            <router-link
              :class="{
                'text-red-600 border-b-2 border-red-600':
                  $route.fullPath == '/tellerportal',
              }"
              to="/tellerportal"
              class="block py-1 md:py-3 pl-1 align-middle no-underline hover:text-red-900 border-b-2 border-orange-600 hover:border-red-600"
            >
              <span class="pb-1 md:pb-0 text-sm">Agency Portal</span>
            </router-link>
          </li>
          <li class="mr-6 my-2 md:my-0">
            <router-link
              :class="{
                'text-red-600 border-b-2 border-red-600':
                  $route.fullPath == '/agent_transactions',
              }"
              to="/agent_transactions"
              class="block py-1 md:py-3 pl-1 align-middle no-underline hover:text-red-900 border-b-2 border-orange-600 hover:border-red-600"
            >
              <span class="pb-1 md:pb-0 text-sm">Transactions</span>
            </router-link>
          </li>

          <li v-if="user.data.type == 'admin'" class="mr-6 my-2 md:my-0">
            <router-link
              :class="{
                'text-red-600 border-b-2 border-red-600': $route.fullPath == '/agents',
              }"
              to="/agents"
              class="block py-1 md:py-3 pl-1 align-middle no-underline hover:text-red-900 border-b-2 border-orange-600 hover:border-red-600"
            >
              <span class="pb-1 md:pb-0 text-sm">Users</span>
            </router-link>
          </li>

          <li class="mr-6 my-2 md:my-0">
            <span
              class="pb-1 flex md:pb-0 text-sm cursor-pointer"
              @click="handleDropDown"
            >
              <span class="cursor-none"> Settings</span>
              <svg
                class="ml-2 w-4 h-4"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path></svg
            ></span>
            <!-- Dropdown menu -->

            <div
              v-if="dropdown"
              class="z-10 absolute mt-2 w-60 bg-white shadow rounded divide-y divide-gray-100 border"
            >
              <div class="py-3 px-4 text-sm text-gray-900 dark:text-white">
                <div>Your Account</div>
              </div>
              <ul
                class="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownInformationButton"
              >
                <li>
                  <router-link
                    :class="{
                      'text-red-600': $route.fullPath == '/settings/change_password',
                    }"
                    to="/settings/change_password"
                    class="block px-2 align-middle no-underline hover:text-red-900"
                  >
                    <a
                      href="#"
                      class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >Change Password</a
                    >
                  </router-link>
                </li>
                <li>
                  <router-link
                    :class="{
                      'text-red-600': $route.fullPath == '/settings/report',
                    }"
                    :to="{
                      path: 'settings/report',
                      params: { user_id: user.data.id },
                    }"
                    class="block px-2 align-middle no-underline hover:text-red-900"
                  >
                    <a
                      href="#"
                      class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >Reports</a
                    >
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <div class="relative pull-right pl-4 pr-4 md:pr-0"></div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    // LocaleDropdown
  },

  data: () => ({
    appName: "Chapman Xpress",
    dropdown: false,
  }),

  computed: mapGetters({
    user: "auth/user",
  }),

  methods: {
    setFullScreen: function () {
      // window.close();
      window.resizeTo(screen.width, screen.height);
      window.statusbar;
      console.log(window.resizeTo);
    },

    handleDropDown() {
      this.dropdown = !this.dropdown;
    },

    async handleLogout() {
      await this.$store.dispatch("auth/logout");

      this.$router.push({ name: "login" });
    },

    minimize() {},
  },
};
</script>

<style scoped>
@media print {
  body * {
    visibility: hidden;
  }

  #hide-to-print #hide-to-print * {
    visibility: hidden;
  }

  .__hide__to_print {
    visibility: hidden;
  }
}
</style>
