import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import * as types from "../mutation-types";

// state
export const state = {
  user: null,
  token: Cookies.get("token"),
};

// getters
export const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  check: (state) => state.user !== null,
};

// mutations
export const mutations = {
  [types.SAVE_TOKEN](state, { token, remember }) {
    state.token = token;
    Cookies.set("token", token, { expires: remember ? 365 : null });
  },

  [types.FETCH_USER_SUCCESS](state, { user }) {
    state.user = user;
  },

  [types.FETCH_USER_FAILURE](state) {
    state.token = null;

    Cookies.remove("token");
    localStorage.removeItem("token");
  },

  [types.LOGOUT](state) {
    state.user = null;
    state.token = null;

    Cookies.remove("token");
    localStorage.removeItem("token");
  },

  [types.UPDATE_USER](state, { user }) {
    state.user = user;
  },
};

// actions
export const actions = {
  saveToken({ commit }, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  async fetchUser({ commit }) {
    try {
      const { data } = await axios.get("/agent/profile");
      console.log("data.... status", data.data.status);
      // logout user out if the status is inactive...
      if (!data.data.status) {
        try {
          await axios.post("/agent/logout");
          Cookies.remove("token");
          localStorage.removeItem("token");
          Swal.fire({
            icon: "warning",
            title: "Account Status",
            text: `Your account is in-active`,
          });
        } catch (error) {
          console.error("error", error);
        }
      } else {
        commit(types.FETCH_USER_SUCCESS, { user: data });
      }
    } catch (e) {
      console.error(e);
      // console.log('SSS', e.response.status);

      if (e.response.status === 403) {
        Swal.fire({
          icon: "warning",
          title: "Unauthorized",
          text: "Unauthorized your account is  in-active",
        });
      }
      commit(types.FETCH_USER_FAILURE);
    }
  },

  updateUser({ commit }, payload) {
    commit(types.UPDATE_USER, payload);
  },

  async logout({ commit }) {
    try {
      await axios.post("/agent/logout");
      Swal.fire({
        icon: "warning",
        title: "logout",
        text: "Agent Signed out successfully",
      });
    } catch (e) {
      // catch
    }

    commit(types.LOGOUT);
  },

  async fetchOauthUrl(ctx, { provider }) {
    const { data } = await axios.post(`/api/oauth/${provider}`);

    return data.url;
  },
};
