import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './assets/main.css';
import axios from 'axios';
import Chart from 'vue2-frappe';
// import LaravelVuePagination from 'laravel-vue-tailwind-pagination';
import Spinner from './components/Spinner.vue';
import vSelect from 'vue-select';
import Pagination from './components/Pagination';

import 'vue-select/dist/vue-select.css';

import VModal from 'vue-js-modal';

// import firebaseMessaging from '../firebase';

// Vue.prototype.$messaging = firebaseMessaging;


Vue.use(VModal);

Vue.component('pagination', Pagination);

Vue.component('v-select', vSelect);
Vue.component('spinner', Spinner);

// Vue.use(LaravelVuePagination);
Vue.use(Chart);

import Multiselect from 'vue-multiselect';

// register globally
Vue.component('multiselect', Multiselect);

import Swal from 'sweetalert2';

import './plugins';
import './components';
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

Vue.config.productionTip = false;

window.Swal = Swal;

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

window.Toast = Toast;

const moment = require('moment');

Vue.use(require('vue-moment'), {
  moment,
});

Vue.use(require('vue-moment'));

new Vue({
  store,
  router,
  ...App,
});
