var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800 border-b",attrs:{"id":"hide-to-print"}},[_c('div',{staticClass:"fixed flex flex-col top-0 left-0 w-full bg-white border-r",attrs:{"id":"hide-to-print"}},[_c('div',{staticClass:"overflow-y-auto overflow-x-hidden flex-grow",attrs:{"id":"hide-to-print"}},[_c('ul',{staticClass:"flex mt-10 py-4 space-y-1"},[_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-600 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
              'bg-gray-50 border-indigo-500': _vm.$route.fullPath == '/dashboard',
            },attrs:{"to":"/dashboard"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Dashboard")])])],1),_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",class:{
              'bg-gray-50 border-indigo-500':
                _vm.$route.fullPath == '/agent_transactions',
            },attrs:{"to":"/agent_transactions"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('i',{staticClass:"bx bx-transfer text-xl"})]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Transactions")])])],1),_vm._m(0),_c('li',[_c('router-link',{staticClass:"relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6",attrs:{"to":"/settings/profile"}},[_c('span',{staticClass:"inline-flex justify-center items-center ml-4"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}})])]),_c('span',{staticClass:"ml-2 text-sm tracking-wide truncate"},[_vm._v("Settings")])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"px-5"},[_c('div',{staticClass:"flex flex-row items-center h-8"},[_c('div',{staticClass:"text-sm font-light tracking-wide text-gray-500"})])])
}]

export { render, staticRenderFns }